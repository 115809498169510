import React from 'react';
import HomePage from './Pages/homePage/homePage';

export default function App() {
  return (
    <div>
      <HomePage />
    </div>
  );
}
